<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="140px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >

    <el-form-item label="充值面值">
      <el-col :span="9">

        <el-input
          v-model="formModel.price"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="充值面值描述">
      <el-col :span="9">
        <el-input
          v-model="formModel.price_des"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="">
      <el-col :span="9">
        <el-alert
          :closable="false"
          :title="formModel.price_des + formModel.price"
          type="info"
        >
        </el-alert>
      </el-col>
    </el-form-item>

    <el-form-item label="排列顺序">
      <el-col :span="9">
        <el-input
          type="number"
           :min="0"
          v-model="formModel.sort"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="另赠送">
      <el-col :span="9">
        <el-input
          v-model="formModel.add_price"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="另赠送描述">
      <el-col :span="9">
        <el-input
          v-model="formModel.add_price_des"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="">
      <el-col :span="9">
        <el-alert
          :closable="false"
          :title="formModel.add_price_des + formModel.add_price"
          type="info"
        >
        </el-alert>
      </el-col>
    </el-form-item>

    <el-form-item label="是否推荐">
      <el-switch
        v-model="formModel.is_tui"
        active-color="#409eff"
      >
      </el-switch>
    </el-form-item>

    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>

  </el-form>
</template>

<script>
export default {
  name: 'FreeFrom',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formModel: null,
      formRules: null
    }
  },

  async created () {
    this.formModel = Object.assign(this.model)
  },

  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true
        console.log(this.formModel)
        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
