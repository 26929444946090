<template>
    <div class="box">
        <div class="box-header">
            <h4>{{ pageTitle }}</h4>
        </div>
        <free-form
            v-if="model"
            :submit-text="pageTitle"
            :model="model"
            :is-edit="true"
            @on-submit="createFreeCreate"
        >
        </free-form>
        <placeholder-form v-else></placeholder-form>
    </div>
</template>

<script>
import MoneyService from '@admin/services/MoneyService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import FreeForm from './components/_From'

export default {
  name: 'FreeEdit',
  components: { PlaceholderForm, FreeForm },
  data () {
    return {
      pageTitle: '编辑储值选项数据',
      model: null
    }
  },
  async created () {
    const { data } = await flatry(
      MoneyService.edit(this.$router.currentRoute.query.id)
    )

    if (data) {
      this.model = data.data
    }
  },
  methods: {
    async createFreeCreate (model, success, callback) {
      const { data } = await flatry(MoneyService.edit(model.id, model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/money/update',
          query: { id: data.data }
        })

        success()
      }

      callback()
    }
  }
}
</script>
<style lang='scss' scoped>
</style>
