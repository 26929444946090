import Vue from 'vue'

const MoneyService = {
  // 储值列表
  all (param) {
    return Vue.http.get('money/index', param)
  },
  // 创建
  create (model = null) {
    const endpoint = 'money/create'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  },
  // 编辑
  edit (id, model = null) {
    if (model === null) {
      return Vue.http.get('money/update', { id: id })
    }

    return Vue.http.put('money/update', model, { id: id })
  },
  del (param) {
    return Vue.http.delete('money/delete', param)
  },
  home (param) {
    return Vue.http.put('money/money-home', param)
  },

  // 所有免单用户列表
  allMd (query) {
    return Vue.http.get('money/free', query)
  },
  createFree (model = null) {
    const endpoint = 'money/free-create'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  },
  editFree (id, model = null) {
    const endpoint = 'money/free-update'

    if (model === null) {
      return Vue.http.get(endpoint, { id: id })
    }

    return Vue.http.put(endpoint, model, { id: id })
  },
  deleteFree (model) {
    return Vue.http.delete('money/free-delete', model)
  },
  status (id, model) {
    return Vue.http.put('money/free-status', model, { id: id })
  }
}

export default MoneyService
